
// PICTURES
import top100Img from "../pictures/top100.png";

// VIDEO
import videoBackground from "../video/video1.mp4";
import videoBackgroundPic from "../pictures/video_background.jpg";

export default function Thanks({ language, logoUrl, logoText }) {

    return(
        <>
        <div className="video-container">
            <video 
            autoPlay={true} 
            loop={true}
            controls={false} 
            playsInline
            muted
            src={videoBackground} 
            style={{
                backgroundImage: "url(" + videoBackgroundPic + ")"  
            }}
            type="video/mp4" 
            >
            </video>
        </div>

        <div className='flex-col align-center'
        style={{
            paddingLeft: "var(--gap2)",
            paddingRight: "var(--gap2)",
            paddingTop: "120px",
            paddingBottom: "120px"
        }}
        >  
          <div
          style={{
            maxWidth: "var(--maxWidth)",
            width: "100%",
            zIndex: "20",
          }}>



            <section>
                <div className="flex-col white-text">
                    <div className="flex-col align-center gap-2">
                        <div className="headerContainer">
                            <div className="thanksImg">
                                <img 
                                className="top10img"
                                src={top100Img}
                                alt="top 100 dnb"
                                ></img>
                            </div>  

                            <div className="flex-col ct_bold gap-1">
                                <h1 >{language.text1}</h1>
                                <h2>{language.text2}</h2>
                            </div>
                        </div>
                        
                        <div className="flex-col width-full ct_light pcPad">
                            <p className="ct_light"> {language.text3}</p>
                            <p className="ct_light">{language.text4}</p>
                            
                            <a href={logoUrl} >
                                <div className='flex-col mt-3'>
                                    <button 
                                    className='grad red width-fit'
                                    >{logoText}</button>
                                </div>
                            </a>
                        </div>




                        <div className="thanksImgMobile">
                            <img 
                            className="top10img"
                            src={top100Img}
                            alt="top 100 dnb"
                            ></img>
                        </div>  
                    </div>
                </div>
            </section>
          </div>
        </div>
        </>
    )
}