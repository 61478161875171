

// HEADER
export const EnLanguage = {
    id: "en",
    text1: "Hey Immortalz",
    text2: "You know Drum & Bass like nobody else and your taste is really worth it, it's time to make your voices heard and decide which tracks have defined the year for you, the Immortalz!",
    text2_1: "Voting in first round has already ended.",
    text3: "Cast your vote in the poll for your favorite Drum & Bass track", 
    text3_1: " released in the year 2023.", 
    text4: "- In the first round of voting, you can vote for multiple tracks.",
    text4_1: "- You can cast votes for up to 10 D&B tracks",
    text4_2: "- You can use the whisperer or write any track you want.",
    text5: "We will close the first round of voting at the end of April.",
    text6: "- The second round will take place during May 2024.",
    text7: "By voting, you’re also entering a draw to win one of ten tickets to the summer Let It Roll festival.",
    text8: "The top 10 winning tracks will be announced live at Let It Roll 2024, during a brand-new festival show on Saturday at the Main Stage.",
    text9: "Thank you, Immortalz! We can’t wait to see which tracks you’ll choose!",
    input1: "ARTIST NAME",
    input1Err: "*ARTIST NAME IS MANDATORY",
    btn1: "CONFIRM",
    btn2: "SELECT",
    input2: "TRACK",
    input2Err: "*TRACK IS MANDATORY",
    text10: "You vote for:",
    text11: "ADD NEW TRACK",
    input3: "YOUR NAME",
    input3Err: "*NAME IS MANDATORY",
    input3Err2: "*CHOOSE AT LEAST ONE TRACK",
    input3Err3: "*YOU CAN VOTE FOR A MAXIMUM OF 10 D&B TRACKS",
    input4: "YOUR EMAIL",
    input4Err: "*EMAIL IS MANDATORY",
    input4Err2: "*THIS DOESN'T LOOK LIKE A VALID EMAIL",
    input4Err3: "*YOU'VE ALREADY VOTED",
    text12: "I agree to subscribe to the Let It Roll newsletter.",
    text13: "SEND",
    text14: "SENDING...",

    //SECOND ROUND
    text15: "Immortalz,",
    text16: "Your votes have decided which tracks made it to the second round!",
    text17: "We've narrowed it down to the top 50 tracks, and now it's your chance to make your voice heard once again. This time, you have the power to select up to 10 tracks that will thunder across the Main Stage in a spectacular show, rivaling the grandeur of our opening ceremony.",
    text18: "In the second round of voting, you can simply tick up to 10 drum & bass tunes that have defined your 2023.",
    text19: "Round two is open until Friday, May 24th! Don’t miss your chance to close out together Let It Roll 2024 with a show you have been part of!",
    text20: "By voting, you’re also entering a draw to win one of ten tickets to the summer Let It Roll festival.",
    text21: "Which tracks will rise to the top 10? The power is in your hands, Immortalz!",
    text22: "*tracks are sorted in random order",
}



export const CzLanguage = {
    id:"cz",
    text1: "Hey Immortalz",
    text2: "Protože jste největší fanoušci Drum & Bassu a váš vkus stojí opravdu za to, nastal čas rozhodnout, které tracky za tento rok jsou za vás, Immortalz, nejlepší!",
    text2_1: "Hlasování v prvním kole již skončilo.",
    text3: "Hlasujte v anketě o nejlepší Drum & Bass track, který",
    text3_1: " vyšel v roce 2023.",    
    text4: "- V prvním kole můžete hlasovat pro více tracků.",
    text4_1: "- Můžete hlasovat maximálně pro 10 D&B tracků.",
    text4_2: "- Můžete využít našeptávač, nebo napsat jakýkoliv track, co vás napadne.",
    text5: "První kolo hlasování uzavřeme na konci dubna.",
    text6: "- Druhé kolo hlasování proběhne v květnu 2024.",
    text7: "Díky hlasování se automaticky zapojujete do slosování o vstupenku na letní Let It Roll.",
    text8: "Vyhlášení top 10 vítězných tracků proběhne přímo na Let It Rollu 2024 prostřednictvím nové festivalové show, která proběhne v sobotu na Main stage.",
    text9: "Děkujeme moc, Immortalz! Těšíme se na to, které tracky vyberete!",
    input1: "JMÉNO UMĚLCE",
    input1Err: "*JMÉNO UMĚLCE JE POVINNÉ",
    btn1: "POTVRDIT",
    btn2: "VYBRAT",
    input2: "NÁZEV TRACK",
    input2Err: "*NÁZEV TRACKU JE POVINNÝ",
    text10: "Hlasujete pro:",
    text11: "PŘIDAT TRACK",
    input3: "VAŠE JMÉNO",
    input3Err: "*JMÉNO JE POVINNÉ",
    input3Err2: "*VYBER ALESPOŇ JEDEN TRACK",
    input3Err3: "*MŮŽETE HLASOVAT MAXIMÁLNĚ PRO 10 D&B TRACKŮ",
    input4: "VÁŠ EMAIL",
    input4Err: "*EMAIL JE POVINNÝ",
    input4Err2: "*TOHLE NEVYPADÁ JAKO PLATNÝ EMAIL",
    input4Err3: "*V TÉTO ANKETĚ JSTE UŽ HLASOVALI",
    text12: "Souhlasím s odběrem Let It Roll newsletteru.",
    text13: "ODESLAT",
    text14: "ODESÍLÁM...",

    //SECOND ROUND
    text15: "Immortalz,",
    text16: "Vaše hlasy rozhodly o tom, které tracky postoupily do druhého kola!",
    text17: "Ze všech tracků, pro které jste hlasovali v prvním kole, jsme vybrali 50 těch, které zazněly nejčastěji. Teď je čas, abyste zvolili, jakých 10 skladeb rozduní Main Stage spolu s novou a velkolepou AV show, která proběhne v sobotu od 22:00. ",  
    text18: "Ve druhém kole hlasování můžete jednoduše zaškrtnout až 10 tracků, které formovaly váš rok 2023!",
    text19: "Hlasování bude uzavřeno 24. května a svým hlasem se automaticky zapojujete do slosování o jednu z deseti vstupenek na letní festival Let It Roll.",
    text20: "Hlasováním se zároveň zapojíte do slosování o jednu z deseti vstupenek na letní festival Let It Roll.",
    text21: "Které skladby se vyšplhají do první desítky? Je to pouze ve vašich rukou, Immortalz! ",
    text22: "*tracky jsou seřazené v náhodném pořadí",
}


// FOOTER
export const EnFooter = {
    id: "en",
    logoLink: "https://www.letitroll.eu/",
    link1Text: "Rules for Visitors",
    link1: "https://www.letitroll.eu/rules-for-visitors",
    link2Text: "Payment Plan Terms",
    link2: "https://www.letitroll.eu/ticket-in-istallments",
    link3Text: "Privacy Policy",
    link3: "https://www.letitroll.eu/privacy-policy",
    link4Text: "Terms & Conditions",
    link4: "https://www.letitroll.eu/terms-conditions",
}



export const CzFooter = {
    id: "cz",
    logoLink: "https://www.letitroll.cz/",
    link1Text: "Privacy Policy",
    link1: "https://www.letitroll.cz/privacy-policy",
    link2Text: "Terms & Conditions",
    link2: "https://www.letitroll.cz/terms-conditions",
    link3Text: "Návstěvní řád",
    link3: "https://www.letitroll.cz/rules-for-visitors",
    link4Text: "Nákup na splátky",
    link4: "https://www.letitroll.cz/ticket-in-istallments",
}




//  THANKS PAGE
export const EnNewsletterLanguage = {
    id: "en",
    text1: "Thank you for your vote!",
    text2: "With it, we’re one step closer to new and exciting experiences!",
    text3: "The second round of voting will take place in May. We’ll keep you informed well in advance so you can cast your vote again!",
    text4: "We’re eagerly looking forward to reuniting at Let It Roll after a year apart!",

    // second round
    text5: "Thank you, Immortalz!",
    text6: "Your votes have been counted, and you've helped shape the new TOP10 show of Let It Roll 2024. With your input, we're one step closer to creating unforgettable memories together!",
    text7: "The results will be unveiled live at Let It Roll 2024, during a brand new festival show on Saturday at 10:00 p.m at the Main Stage.",
    text8: "We can't wait to see you at Let It Roll, where we'll dance, celebrate, and create unforgettable memories together once again!"
}


export const CzNewsletterLanguage = {
    id:"cz",
    text1: "Děkujeme za tvůj hlas!",
    text2: "Díky němu jsme blíž dalším a novým zážitkům!",
    text3: "Druhé kolo hlasování proběhne v květnu, dáme vědět včas, ať můžeš hlasovat znovu!",
    text4: "Nemůžeme se dočkat, až se po roce znovu uvidíme na Let It Rollu!",

    // second round
    text5: "Děkujeme!",
    text6: "Tvůj hlas byl započítán!. Opět jsme o krok blíž k novým zážitkům, Immortalz!",
    text7: "Vyhlášení top 10 vítězných tracků proběhne přímo na Let It Rollu 2024 prostřednictvím nové festivalové show v sobotu na Main stage od 22:00.",
    text8: "Nemůžeme se dočkat, až se po roce znovu uvidíme na Let It Rollu!"
}