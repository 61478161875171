import { useState } from 'react';
import { useEffect } from 'react';

// COMPONENTS
import ThanksNewsletter from "../components/ThanksNewsletter";
import Footer from '../components/Footer';

// IMAGES
import top100Img from "../pictures/top100.png"

// LANGUAGE
import { EnFooter } from "../components/Language";

export default function ThanksPageEnNewsletter() {

    const logoUrl = "https://www.letitroll.eu/LetItRoll2024";
    const logoText = "JOIN US AT LET IT ROLL 2024";

    const [allImagesLoaded, setAllImagesLoaded] = useState("0");
    const imageUrls = [top100Img];

    //WAIT TO LOAD ALL IMAGES
    useEffect(() => {

        const loadImage = (url) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });

        const loadAllImages = async () => {
        try {
            const imagePromises = imageUrls.map(loadImage);
            await Promise.all(imagePromises);
            setAllImagesLoaded("1");
        } catch (error) {
            console.error('Error loading images:', error);
        }
        };

        loadAllImages();


        // SCROLL TO THE TOP
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // eslint-disable-next-line
    }, []);
    
return(
    <>
        <div
        style={{
            opacity: allImagesLoaded,
            transition: "1s",
        }}
        >
            <ThanksNewsletter logoUrl={logoUrl} logoText={logoText}/>
            <Footer language={EnFooter}/>
        </div>
    </>
)

}