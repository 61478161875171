
export const apiKeys = {
    
    captcha: process.env.REACT_APP_CAPCHA,
    emailjs: process.env.REACT_APP_EMAILJS_KEY,
    firebaseApiKey: process.env.REACT_APP_FIREBASE_KEY,
    googleId: process.env.REACT_APP_GOOGLE_ID,
    spotifyKeys: [

        {
            id: "1",
            profile: "ondrej",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET,
        }, 
        {
            id: "2",
            profile: "admin grooove",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_2,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_2,
        },
        {
            id: "3",
            profile: "ondrej-2",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_3,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_3,
        },
        {
            id: "4",
            profile: "ondrej-3",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_4,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_4,
        },
        {
            id: "5",
            profile: "ondrej-4",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_5,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_5,
        },
        {
            id: "6",
            profile: "ondrej-5",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_6,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_6,
        },
        {
            id: "7",
            profile: "admin grooove-2",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_7,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_7,
        },
        {
            id: "8",
            profile: "admin grooove-3",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_8,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_8,
        },
        {
            id: "9",
            profile: "admin grooove-4",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_9,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_9,
        },
        {
            id: "10",
            profile: "admin grooove-5",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_10,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_10,
        },
        {
            id: "11",
            profile: "sofa lofi-1",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_11,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_11,
        },
        {
            id: "12",
            profile: "sofa lofi-2",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_12,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_12,
        },
        {
            id: "13",
            profile: "sofa lofi-3",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_13,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_13,
        },
        {
            id: "14",
            profile: "sofa lofi-4",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_14,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_14,
        },
        {
            id: "15",
            profile: "sofa lofi-5",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_15,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_15,
        },
        {
            id: "16",
            profile: "hustej nazef-1",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_16,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_16,
        },
        {
            id: "17",
            profile: "hustej nazef-2",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_17,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_17,
        },
        {
            id: "18",
            profile: "hustej nazef-3",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_18,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_18,
        },
        {
            id: "19",
            profile: "hustej nazef-4",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_19,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_19,
        },
        {
            id: "20",
            profile: "hustej nazef-5",
            spotifyApiId: process.env.REACT_APP_SPOTIFY_ID_20,
            spotifyApiSecret: process.env.REACT_APP_SPOTIFY_SECRET_20,
        },
    ],
}




