import { useState } from 'react';

//FIREBASE AUTH
import { auth } from "../config/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";



const AdminLogIn = ({ adminLogged }) => {

    //LOGIN SAVE
    const [name, setName] = useState("");
    const [password, setPassword] = useState("");
    const [logInState, setLogInState] = useState(false);
    const [logInState2, setLogInState2] = useState(false);

    //INFO LOGIN TEXT
    const [errorText, setErrorText] = useState("");

    //SHOW PASSWORD
    const [showPasswordState, setShowPasswordState] = useState("password");
    const [showBtnText, setShowBtnText] = useState("show");
    const [showToggler, setShowToggler] = useState(false);

    //SHOW LOGIN TABLE

    const showPassword = () => {
        if(showToggler === false){
            setShowPasswordState("text");
            setShowBtnText("hide");
            setShowToggler(true);
        }else{
            setShowPasswordState("password");
            setShowBtnText("show");
            setShowToggler(false);   
        }
    }
    
    //email sign in funkce s error printem
    const logIn = async () => {

        try {
            await signInWithEmailAndPassword(auth, name, password);
            setLogInState(!logInState);
            setLogInState2(!logInState2);

            setErrorText("");
        } catch (err) {
            setErrorText("ŠPATNÝ EMAIL NEBO HESLO");
        }

    };

    //logout funkce
    const logOut = async () => {

        try {
            await signOut(auth);
            setLogInState(!logInState);
            setLogInState2(!logInState2);
        } catch (err) {
            console.error(err);
            setLogInState2(!logInState2);
        }
    };


    return(
        <>

        {// IF ADMIN LOGED IN
        adminLogged === true 
        ?
        <div>
            <button 
            className="adminBtn"
            onClick={logOut}                
            >logout</button>
        </div>  

        // IF ADMIN LOGED OUT
        :
        <div 
        style={{
            position: "absolute",
            zIndex: "100",
            //background: "orange",
            width: "100%",

            display: "flex",
            flexDirection: "column",
            marginTop: "var(--gap4)"
        }}
        >


            <div 
            style={{
            //visibility: showTable, 
            //opacity: showTableOpacity, 

            }}>
                <div
                style={{
                    //background: "grey",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center"
                }}
                >
                    <div
                    style={{
                        //background: "grey",
                        display: "flex",
                        flexDirection: "column",
                        maxWidth: "300px",
                        width: "100%",
                    }}
                    >
                        <input 
                        className='adminLogin'
                        onChange={(e) => setName(e.target.value)}
                        type="text" 
                        placeholder="email" 
                        value={name}
                        ></input>

                        <div 
                        style={{
                        display: "flex", 
                        flexDirection: "row",
                        justifyContent: "space-between"
                        }}>
                            <input 
                            className='adminLogin'
                            style={{
                                width: "100%"
                            }}
                            onChange={(e) => setPassword(e.target.value)}
                            type={showPasswordState} 
                            placeholder="password" 
                            value={password}
                            ></input>

                            <button 
                            className='adminLogin'
                            onClick={showPassword} 
                            >{showBtnText}</button>
                        </div>

                        <button 
                        className='adminLogin'
                        onClick={logIn} 
                        style={{ marginTop: "5px"}} 
                        >login</button>

                        <p className="error">{errorText}</p>
                    </div>
                </div>
            </div>
        </div>
        }
        </>
    )
}

export default AdminLogIn;