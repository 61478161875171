
import { useContext, useEffect, useState } from 'react';

// FIREBASE DATABASE
import { db } from '../config/firebase';
import { getDocs, collection } from 'firebase/firestore';

// CREATE CSV LIB
import { mkConfig, generateCsv, download } from "export-to-csv";

// GLOBAL STATES
import DataContext from '../globalstates/DataContext';

// COMPONENTS
import AdminLogIn from '../components/AdminLogin';
import ResultsNavbarRounds from '../components/ResultsNavbarRounds';
import ResultsNavbar from '../components/ResultsNavbar';
import ResultsTracks from '../components/ResultsTracks';


export default function ResultPageSecondRound() {

    // RESULT STATES
    const [resultData, setResultData] = useState([]);

    // GLOBAL STATES
    const {adminLogged, setAdminLogged } = useContext(DataContext);

    // VYSLEDKY NAVBAR ROUNDS
    const linksRounds = [
        {
            text: "První Kolo",
            path: "/vysledky",
            class: "vysledkyBtn2"
        },

        {
            text: "Druhé Kolo",
            path: "/vysledky-druhe-kolo",
            class: 'vysledkyBtn1'
        },
    ]; 

    // VYSLEDKY NAVBAR
    const links = [
        {
            text: "Výběr s našeptávačem",
            path: "/vysledky-druhe-kolo",
            class: "vysledkyBtn1"
        },

        {
            text: "E-maily (vše)",
            path: "/vysledky-druhe-kolo/kontakty",
            class: 'vysledkyBtn2'
        },

        {
            text: "E-maily (newsletter)",
            path: "/vysledky-druhe-kolo/kontakty_newsletter",
            class: 'vysledkyBtn2'
        },
    ]; 

    // GET ALL VOTES FROM DATABASE
    async function getVotes() {
    try{
        const contentCollectionRef = collection(db, "druhe-kolo-2024");
    
        // Fetch data from external API
        const res = await getDocs(contentCollectionRef);
    
        // RELEASES
        const filteredData = res.docs.map((doc) => ({
            ...doc.data(), 
            id: doc.id, 
        }));
        

        // GET ONLY TRACKS
        let onlyTracks = [];
        filteredData.forEach(item => {

            item.track.forEach(thisTrack => {
                onlyTracks.push(thisTrack)
            })
        })

        // SEPARATE CUSTOM CHOOSED
        const notCustomChoosed = onlyTracks;


        // AVOID DUPLICATES
        function removeDuplicates(arr) {
            const uniqueMap = new Map();
            
            // Iterate through the array and store objects with unique ids in the Map
            arr.forEach(obj => {
                uniqueMap.set(obj.track, obj);
            });
            
            // Convert the Map back to an array of unique objects
            const uniqueArray = Array.from(uniqueMap.values());
            
            return uniqueArray;
        }
        const votedTracks = removeDuplicates(notCustomChoosed);


        const votedTracksSum = votedTracks.map(item => {
            const trackIncludedArr = onlyTracks.filter(i => i.track === item.track);

            return {...item, votes: trackIncludedArr.length}
        })

        const votedTracksSumSort = votedTracksSum.sort((a, b) => b.votes - a.votes);

        // adding index for better listing after filtering
        const votedTracksSumSortIndexed = votedTracksSumSort.map((item, index )=> {
            return {...item, index: index }
        })

        setResultData(votedTracksSumSortIndexed);
    }catch(err) {
        console.log(err);
    }
    }

    // DOWNLOAD CSV
    function downloadCsv() {
        // SET OUTPUT DATA
        const outputData = resultData.map(data => {
            const thisData = {
                nazev_tracku: data.track,
                jmeno_umelce: data.artists.map(i => i.name).join(", "), 
                pocet_hlasu: data.votes,
                url: data.url
            }

            return thisData;
        })

        // GENERATE CSV FROM DATA
        const csvConfig = mkConfig({ 
            useKeysAsHeaders: true, 
            filename:"top-songs" 
        });
        const csv = generateCsv(csvConfig)(outputData);
        download(csvConfig)(csv);
    }

    // RUN GET VOTES ONCE PAGE LOADED
    useEffect(() => {
        if(adminLogged === true) {
            getVotes()
        }
    }, [adminLogged])
    
    return(
        <div className='flex-col align-center pl-1 pr-1'>

            <AdminLogIn adminLogged={adminLogged} setAdminLogged={setAdminLogged}/>

            {adminLogged === true
            // IF ADMIN LOGED IN
            ?
            <div className='flex-col max-width-800 pt-big gap-3'>
                <div className='width-full'>
                    <h2 className='white-text'>Výsledky ankety</h2>
                </div>

                <div className='flex-col gap-1'>
                    <ResultsNavbarRounds links={linksRounds} />
                    <ResultsNavbar links={links} />
                </div>

                <div className='flex-col width-full white-text gap-1'>
                    <h3>Výběr tracku s našeptávačem</h3>
                    <div className="flex-row space-between border-bottom-1 pb-05">
                        <p className='mt-a'>(celkem: {resultData.length})</p>
                        <button className='csvBtn width-fit' onClick={downloadCsv}>csv download 📄</button>
                    </div>
                </div>

                <ResultsTracks resultData={resultData}/>
            </div>

            // IF ADMIN LOGED OUT
            : null
            }
        </div>
    )
}