
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

//funkce na login
import { getAuth, GoogleAuthProvider } from "firebase/auth";

//funkce pro databazi
import{ getFirestore } from "firebase/firestore";

//funkce pro storage
import{ getStorage } from "firebase/storage";

// COMPONENTS
import { apiKeys } from "./apiKeys";

const firebaseConfig = {
    apiKey: apiKeys.firebaseApiKey,
    authDomain: "letitroll-de779.firebaseapp.com",
    projectId: "letitroll-de779",
    storageBucket: "letitroll-de779.appspot.com",
    messagingSenderId: "464026606110",
    appId: "1:464026606110:web:a1ce86583107e4d343ac0f",
    measurementId: apiKeys.googleId
};

//firebase init
const app = initializeApp(firebaseConfig);

/*
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(apiKeys.captcha),
  
    // Optional argument. If true, the SDK automatically refreshes App Check
    // tokens as needed.
    isTokenAutoRefreshEnabled: true
});
*/

//login var
export const auth = getAuth(app);

export const googleProvider = new GoogleAuthProvider();

//var pro databazi
export const db = getFirestore(app);

//var pro storage
export const storage = getStorage(app);