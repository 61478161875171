


//PICTURES
import lirFooter from "../pictures/footer_logo.png";
import facebookImg from "../pictures/icons/facebook.svg";
import instagramImg from "../pictures/icons/instagram.svg";
import twitterImg from "../pictures/icons/twitter.svg";
import youtubeImg from "../pictures/icons/youtube.svg";
import spotifyImg from "../pictures/icons/spotify.svg";

 
export default function Footer({ language }) {

    
return(
    <>

<div className="flex-col white-text gap-05 align-center"
    style={{
        paddingLeft: "var(--gap2)",
        paddingRight: "var(--gap2)",
        paddingTop: "var(--gap4)",
        paddingBottom: "var(--gap4)",
        //minHeight: "200px",
        background: "var(--color5)",
    }}
    >  
        <div 
        className="footerContainer" 
        style={{
            maxWidth: "var(--maxWidth)",
            width: "100%",
            //background: "gray",
            //gap: "150px"
        }}
        >
            <div className="footerLogoContainer">
                <a href={language.logoLink} >
                    <img width={300} src={lirFooter} alt='let it roll'></img>
                </a>
                
                <div className="flex-row gap-1">
                    <a href="https://www.facebook.com/letitrollcz" target="_blank" rel="noopener noreferrer">
                        <img className="footerIcon" src={facebookImg} alt="facebook"></img>
                    </a>

                    <a href="https://www.instagram.com/letitrollfestival" target="_blank" rel="noopener noreferrer">
                        <img className="footerIcon" src={instagramImg} alt="instagram"></img>
                    </a>

                    <a href="https://twitter.com/letitroll_fest" target="_blank" rel="noopener noreferrer">
                        <img className="footerIcon" src={twitterImg} alt="twitter"></img>
                    </a>

                    <a href="https://www.youtube.com/@LetItRollfestival" target="_blank" rel="noopener noreferrer">
                        <img className="footerIcon" src={youtubeImg} alt="youtube"></img>
                    </a>

                    <a href="https://open.spotify.com/user/e5es0iqv1yhb403q9orfme7g8" target="_blank" rel="noopener noreferrer">
                        <img className="footerIcon" src={spotifyImg} alt="spotify"></img>
                    </a>
                </div>
            </div>


            <div className="flex-row gap-3">
                <div className="flex-col gap-1">
                    
                    <a className="linkHover" href={language.link1} >
                        <p className="ct_bold">{language.link1Text}</p>
                    </a>

                    <a className="linkHover" href={language.link2} >
                        <p className="ct_bold">{language.link2Text}</p>
                    </a>
                </div>

                <div className="flex-col gap-1">
                    <a className="linkHover" href={language.link3} >
                        <p className="ct_bold">{language.link3Text}</p>
                    </a>

                    <a className="linkHover" href={language.link4} >
                        <p className="ct_bold">{language.link4Text}</p>
                    </a>
                </div>
            </div>

        </div>


    </div>


    <div className="flex-col white-text gap-05 align-center"
    style={{
        paddingLeft: "var(--gap2)",
        paddingRight: "var(--gap2)",
        paddingTop: "var(--gap4)",
        paddingBottom: "125px",
        //minHeight: "200px",
        background: "var(--color1)",
        fontSize: "12px"
    }}
    >  
            <div 
            className="flex-col"
            style={{
                maxWidth: "var(--maxWidth)",
                width: "100%",
                //background: "orange",
            }}
            >
                <p className="ct_medium">
                    © 2024 Beatworx s.r.o. | Všechna práva vyhrazena. 
                </p>

                <p className="ct_medium">
                    All audiovisual materials on the website are the property of Beatworx s.r.o. and the Let It Roll festival.
                </p>

                <p className="ct_medium">
                    Distribution of these materials is prohibited without prior permission
                </p>
            </div>
    </div>
    </>
)

}