import { useState } from 'react';
import { useEffect } from 'react';

// LANGUAGE
import { CzNewsletterLanguage, CzFooter } from "../components/Language";


// COMPONENTS
import ThanksSecondRound from '../components/ThanksSecondRound';
import Footer from '../components/Footer';


// PICTURES
import top100Img from "../pictures/top100.png";


export default function DekujemeSecondRound() {

    const logoUrl = "https://www.letitroll.cz/LetItRoll2024";
    const logoText = "PŘIPOJTE SE K LET IT ROLL 2024";

    const [allImagesLoaded, setAllImagesLoaded] = useState("0");
    const imageUrls = [top100Img];

    //WAIT TO LOAD ALL IMAGES
    useEffect(() => {

        const loadImage = (url) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });

        const loadAllImages = async () => {
        try {
            const imagePromises = imageUrls.map(loadImage);
            await Promise.all(imagePromises);
            setAllImagesLoaded("1");
        } catch (error) {
            console.error('Error loading images:', error);
        }
        };

        loadAllImages();

        // SCROLL TO THE TOP
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        // eslint-disable-next-line
    }, []);
    
return(
    <>
    <div
    style={{
        opacity: allImagesLoaded,
        transition: "1s",
    }}
    >
        <ThanksSecondRound language={CzNewsletterLanguage} logoUrl={logoUrl} logoText={logoText} />
        <Footer language={CzFooter}/>
    </div>
    </>
)

}