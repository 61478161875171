import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';


// COMPONENTS
import NavBar from "../components/NavBar";

import MainPage from "../components/MainPage";
import MainPageBetween from '../components/MainPageBetween';
import MainPageSecondRound from '../components/MainPageSecondRound';
import MainPageThirdRound from '../components/MainPageThirdRound';

import Footer from '../components/Footer';

// IMAGES
import top100Img from "../pictures/top100.png"

// LANGUAGES
import { CzLanguage, CzFooter, CzNewsletterLanguage } from '../components/Language';

export default function Cz() {

    const [allImagesLoaded, setAllImagesLoaded] = useState("0");
    const imageUrls = [top100Img];

    // BUTTON URL AND TEXT
    const logoUrl = "https://www.letitroll.cz/LetItRoll2024";
    const logoText = "PŘIPOJTE SE K LET IT ROLL 2024";

    //WAIT TO LOAD ALL IMAGES
    useEffect(() => {

        const loadImage = (url) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });

        const loadAllImages = async () => {
        try {
            const imagePromises = imageUrls.map(loadImage);
            await Promise.all(imagePromises);
            setAllImagesLoaded("1");
        } catch (error) {
            console.error('Error loading images:', error);
        }
        };

        loadAllImages();
        // eslint-disable-next-line
    }, []);

    return(
        <>
        <Helmet>
            <meta
            name="description"
            content="Hlasujte v anketě o nejlepší Drum & Bass track, který vyšel v roce 2023."
            />
        </Helmet>

        <div>
            <NavBar />
            
            <section
                style={{
                opacity: allImagesLoaded,
                transition: "1s",
            }}
            >
                {/*
                <MainPage language={CzLanguage} />
                */}

                {/*
                <MainPageBetween language={CzLanguage} />
                */}

                {/*
                <MainPageSecondRound language={CzLanguage} />
                */}


                <MainPageThirdRound 
                language={CzNewsletterLanguage} 
                logoUrl={logoUrl}
                logoText={logoText}
                />

            </section>

            <Footer language={CzFooter}/>

        </div>
        </>
    )
}