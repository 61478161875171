




import { useEffect, useRef, useState } from 'react';

// VIDEO
import videoBackground from "../video/video1.mp4";
import videoBackgroundPic from "../pictures/video_background.jpg";

// PIC
import top100Img from "../pictures/top100.png"

export default function MainPageThirdRound({ language, logoUrl, logoText }) {

    // VIDEO
    const videoRef = useRef()

    // PLAY AFTER LOAD
    useEffect(() => {
        videoRef.current.play()
    }, [])

    return (        
        <div className='flex-col align-center'
        style={{
            paddingLeft: "var(--gap2)",
            paddingRight: "var(--gap2)",
        }}
        >
            <div className="video-container">
                <video 
                ref={videoRef}
                //autoPlay={true} 
                loop={true}
                controls={false} 
                playsInline
                muted
                src={videoBackground} 
                style={{
                    backgroundImage: "url(" + videoBackgroundPic + ")"  
                }}
                type="video/mp4" 
                >
                </video>
            </div>

            <div
            style={{
            maxWidth: "var(--maxWidth)",
            width: "100%",
            zIndex: "10",
            marginBottom: "200px"
            }}
            >        
                        <section>
            <div className="flex-col white-text">
                <div className="flex-col align-center gap-3">



                    <div className="headerContainer">
                        <div className="headerImg">
                            <img 
                            className="top10img"
                            src={top100Img}
                            alt="top 100 dnb"
                            ></img>
                        </div>  

                        <div className="flex-col ct_bold gap-1">
                            <h1 >Hey Immortal,</h1>
                            <h2>{language.text6}</h2>
                        </div>
                    </div>

                    <div className="flex-col width-full ct_bold">
                        <p><strong>{language.text7}</strong></p>
                    </div>

                    <div className="flex-col width-full ct_light">
                        <p>{language.text8}</p>
                    </div>

                    <div className="flex-col width-full">
                        <a href={logoUrl} className='mt-3 width-fit'>
                            <div className='flex-col'>
                                <button 
                                className='grad red width-fit'
                                >{logoText}</button>
                            </div>
                        </a>
                    </div>

                </div>

            </div>
        </section>
           
            </div>
        </div>
    );
}