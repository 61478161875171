
import { useNavigate } from 'react-router-dom';

// PICTURES
import logo from "../pictures/logo.webp";
import engFlag from "../pictures/eng.svg"
import czFlag from "../pictures/cz.svg"

export default function NavBar() {

  // ROATER
  const navigate = useNavigate()

  return(
      <div className='flex-row justify-end'
      style={{
        paddingLeft: "var(--gap2)",
        paddingRight: "var(--gap2)",
        paddingTop: "var(--gap1)",
        zIndex: "10",
        //background: "orange",
      }}
      >
        <div className="flex-row gap-2 width-fit">

            <img 
            style={{
              maxWidth: "50px",
              cursor: "pointer"
            }}
            src={engFlag} 
            alt='english'
            onClick={() => {
              navigate("/en")
            }}
            ></img>


            <img 
            style={{
              maxWidth: "50px",
              cursor: "pointer"
            }}
            src={czFlag} 
            alt='czech'
            onClick={() => {
              navigate("/cz")
            }}
            ></img>

          </div>
      </div>
  )
}