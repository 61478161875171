

// COMPONENTS
import React, { useEffect, useState } from 'react';

// FIREBASE
import { auth, googleProvider } from "./config/firebase";

// ROASTER
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

// GLOBAL STATES
import DataContext from './globalstates/DataContext';

// PAGES
import Main from './pages/Main';
import AdminPage from "./components/AdminPage";

import ResultPage from './pages/ResultPage';
import ResultPageSecondRound from './pages/ResultPageSecondRound';

import ResultPageCustom from './pages/ResultPageCustom';

import ResultPageKontakty from './pages/Kontakty';
import ResultPageKontaktySecondRound from './pages/KontaktySecondRound';

import ResultPageKontaktyNewsletter from './pages/KontaktyNewsletter';
import ResultPageKontaktyNewsletterrSecondRound from './pages/KontaktyNewsletterSecondRound';

import ThanksPageEn from './pages/ThanksPageEn';
import ThanksPageEnSecondRound from './pages/ThanksPageEnSecondRound';

import ThanksPageEnNewsletter from './pages/ThanksPageEnNewsletter';

import Dekujeme from './pages/Dekujeme';
import DekujemeSecondRound from './pages/DekujemeSecondRound';

import En from './pages/En';
import Cz from './pages/Cz';

// LANGUAGE
import { EnLanguage, CzLanguage } from './components/Language';

// API KEYS
import { apiKeys } from './config/apiKeys';


// router pages
const router = createBrowserRouter([
    {
        path: "/",
        exact: true,
        element: <Main />,
    },

    {
        path: "/en",
        exact: true,
        element: <En />,
    },

    {
        path: "/en/thanks",
        exact: true,
        element: <ThanksPageEn />,
    },

    {
        path: "/en/thanks-2",
        exact: true,
        element: <ThanksPageEnSecondRound />,
    },

    {
        path: "/en/thanks-newsletter",
        exact: true,
        element: <ThanksPageEnNewsletter />,
    },

    {
        path: "/cz",
        exact: true,
        element: <Cz />,
    },

    {
        path: "/cz/dekujeme",
        exact: true,
        element: <Dekujeme />,
    },

    {
        path: "/cz/dekujeme-2",
        exact: true,
        element: <DekujemeSecondRound />,
    },

    {
        path: "/admin",
        exact: true,
        element: <AdminPage />,
    },
    {
        path: "/vysledky",
        exact: true,
        element: <ResultPage />,
    },
    {
        path: "/vysledky-druhe-kolo",
        exact: true,
        element: <ResultPageSecondRound />,
    },
    
    {
        path: "/vysledky/custom",
        exact: true,
        element: <ResultPageCustom />,
    },
    
    {
        path: "/vysledky/kontakty",
        exact: true,
        element: <ResultPageKontakty />,
    },

    {
        path: "/vysledky-druhe-kolo/kontakty",
        exact: true,
        element: <ResultPageKontaktySecondRound />,
    },
    
    {
        path: "/vysledky/kontakty_newsletter",
        exact: true,
        element: <ResultPageKontaktyNewsletter />,
    },

    {
        path: "/vysledky-druhe-kolo/kontakty_newsletter",
        exact: true,
        element: <ResultPageKontaktyNewsletterrSecondRound />,
    },
]);


export default function App({}) {

    // ADMIN STATES
    const [adminLogged, setAdminLogged] = useState(false);

    // COOCKIES
    const coockiesAccepted = true;
    

    // CHECK IF ADMIN IS LOGGED IN 
    useEffect(() => {
        // LOGIN LISTENER
        const unsubscribe = auth.onAuthStateChanged(user => {
            if(user) {
                //console.log("LOGGED IN"); 
                setAdminLogged(true);
            } else{
                //console.log("LOGGED OUT"); 
                setAdminLogged(false);
            }
        });
    
        // Cleanup function
        return () => unsubscribe();
    }, []); // Empty dependency array to run the effect only once



   // LOAD GOOGLE ANALYTICS
   function loadGoogleAnalytics() {
    const script = document.createElement("script");
    script.src = `https://www.googletagmanager.com/gtag/js?id=${apiKeys.googleId}`;
    script.async = true;
    document.head.appendChild(script);
    
    let layer = window.dataLayer = window.dataLayer || [];

    function gtag(){
      layer.push(arguments);
    }

    gtag('js', new Date());
    gtag('config', `${apiKeys.googleId}`, {
      page_path: window.location.pathname
    })
  }

  // CHECK IF USER AGREE WITH COOCKIES
  useEffect(() => {
    if(coockiesAccepted === true) {
      loadGoogleAnalytics();
    }
  }, [coockiesAccepted])   


    return (
        <>
        <DataContext.Provider value={{adminLogged, setAdminLogged, EnLanguage, CzLanguage }}>
            {/*<NavBar />*/}
            <RouterProvider router={router} />

        </DataContext.Provider>
        </>
      )
    


}