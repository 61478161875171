import { useState } from 'react';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

// COMPONENTS
import NavBar from "../components/NavBar";

import MainPage from "../components/MainPage";
import MainPageBetween from '../components/MainPageBetween';
import MainPageSecondRound from '../components/MainPageSecondRound';
import MainPageThirdRound from '../components/MainPageThirdRound';

import Footer from '../components/Footer';

// IMAGES
import top100Img from "../pictures/top100.png"

// LANGUAGES
import { EnLanguage, EnFooter, EnNewsletterLanguage } from '../components/Language';


export default function En() {


    const [allImagesLoaded, setAllImagesLoaded] = useState("0");
    const imageUrls = [top100Img];

    const logoUrl = "https://www.letitroll.eu/LetItRoll2024";
    const logoText = "JOIN US AT LET IT ROLL 2024";

    //WAIT TO LOAD ALL IMAGES
    useEffect(() => {

        const loadImage = (url) =>
        new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve(img);
            img.onerror = reject;
            img.src = url;
        });

        const loadAllImages = async () => {
        try {
            const imagePromises = imageUrls.map(loadImage);
            await Promise.all(imagePromises);
            setAllImagesLoaded("1");
        } catch (error) {
            console.error('Error loading images:', error);
        }
        };

        loadAllImages();
        // eslint-disable-next-line
    }, []);

    return(
        <>
            <Helmet>
                <meta
                name="description"
                content="Cast your vote in the poll for your favorite Drum & Bass track released in the year 2023."
                />
            </Helmet>

            
            <div>
                <NavBar />
                <section
                style={{
                    opacity: allImagesLoaded,
                    transition: "1s",
                }}
                >
                    {/*
                    <MainPage language={EnLanguage}/>
                    */}

                    {/*
                    <MainPageBetween language={EnLanguage}/>
                    */}

                    {/*
                    <MainPageSecondRound language={EnLanguage}/>
                    */}

                <MainPageThirdRound 
                language={EnNewsletterLanguage} 
                logoUrl={logoUrl}
                logoText={logoText}
                />

                </section>

                <Footer language={EnFooter}/>
            </div>
        </>
    )
}