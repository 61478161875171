import { useEffect, useState } from 'react';

// PICTURES
import nextImg from "../pictures/next.svg";

export default function ResultsTracks({ resultData }) {

    // STATES
    const [currentPage, setCurrentPage] = useState(0);

    const [backBtn, setBackBtn] = useState({
        state: true,
        class: "nextBtn2",
    });
    const [nextBtn, setNextBtn] = useState({
        state: true,
        class: "nextBtn1",
    });
    const maxResultsOnPage = 25;
    const thisPage = resultData.filter((e,i) => i >= currentPage * maxResultsOnPage  && i < (currentPage + 1) * maxResultsOnPage)

    // "BACK/NEXT" BUTTONS "ENABLE/DISABLE" LOGIC
    useEffect(() => {

        const currentTopRange = currentPage * maxResultsOnPage;
        const limitTopRange = resultData.length - maxResultsOnPage;
        
        if(currentTopRange > limitTopRange) {
            setNextBtn({
                state: true,
                class: "nextBtn2",
            });
        } else{
            setNextBtn({
                state: false,
                class: "nextBtn1",
            });  
        }

        if(currentPage > 0){
            setBackBtn({
                state: false,
                class: "nextBtn1",
            });
        } else{
            setBackBtn({
                state: true,
                class: "nextBtn2",
            });
        }
        
    }, [currentPage, resultData])


    
    return(
        <div className='flex-col white-text width-full gap-1'>
            {thisPage.map((item, index) => {

                return(
                    <div key={index} className="flex-row space-between p-1 align-center gap-2" 
                    style={{
                        //background: "orange"
                    }}>
                        <div className='flex-row align-center gap-1' >
                            <p style={{
                                //background: "orange",
                                minWidth: "30px"
                            }}>{item.index + 1}</p>

                            {item.img !== null 
                            ? <img className="resultsImg" src={item.img}></img> 
                            : null
                            }

                            <div className='flex-col'
                            >
                                <a href={item.url} target="_blank" rel="noopener noreferrer"><h4 className='white-text truncate-1'>{item.track}</h4></a>
                                <p className='truncate-1'>{item.artists.map(i => i.name).join(", ")}</p>
                            </div>
                        </div>
                        {item.votes 
                            ? <h2 title='počet hlasů'><strong>{item.votes}</strong></h2> 
                            : null
                        }
                    </div>
                )
            })}

            <div className='flex-row justify-center gap-4 mt-2'>
                <button 
                className={ backBtn.class + " width-fit" }
                disabled={backBtn.state} 
                onClick={() => {
                    setCurrentPage(currentPage - 1)
                }}
                >
                    <img className='nextImg flip' src={nextImg}></img>
                </button>

                <button 
                className={ nextBtn.class + " width-fit" }
                disabled={nextBtn.state}
                onClick={() => {
                    setCurrentPage(currentPage + 1)
                }}
                >
                    <img className='nextImg' src={nextImg}></img>
                </button>
            </div>
            <br/>
            <br/>
        </div>

    )
}