

import { Link } from 'react-router-dom';

export default function ResultsNavbar({ links }) {

    return(
        <div className="flex-row width-full white-text gap-05 pb-05">
            {links.map((link, index) => {

                return(
                    <Link to={link.path} className={link.class} key={index}>{link.text}</Link>
                )
            })}
        </div>  
    )
}